<template>
  <div class="modal__container-inner">
    <div class="accept accept__box">
      <h3 class="accept__title modal__mb-20 title-h4">
        {{ $t('modals.select_role') }}
      </h3>
      <div class="auth-roles-list">
        <SelectField
          v-model="role"
          :items="roles"
          :title="'Оберіть роль'"
          isObjectType
          valueToReturn="object"
          :placeholder="'Оберіть роль'"
          titleKey="title"
          valueKey="value"
        />
      </div>
      <div class="modal__btn-2col-center">
        <Button white @click.native="closeModal" :disabled="!isRequestLoaded">
          {{ $t('modals.cancel') }}
        </Button>
        <Button @click.native="acceptHandler" :disabled="!role">
          {{ $t('modals.confirm') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectField from '../SelectField.vue'

export default {
  components: {
    SelectField,
  },
  data() {
    return {
      role: '',
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps', 'getModalHandler']),
    ...mapGetters(['isRequestLoaded', 'getAuthRoles', 'getKeySignData']),

    roles() {
      return this.getAuthRoles.map(el => {
        return {
          title: this.$t(`sections.${el}`),
          value: el,
        }
      })
    }
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },

    async acceptHandler() {

      await this.$store.dispatch('authorisationWithEuKey', {
        role: this.role.value,
        raw_sign: this.getKeySignData,
      });
      this.closeModal();
    },
  },
};
</script>

<style lang="sass" scoped>
.accept
  &__title
    text-align: center

.accept__box
  padding: 0 80px

.accept__content
  color: $gray
  margin-bottom: 60px

.modal__btn-2col-center
  flex-wrap: nowrap
  padding-top: 4rem

::v-deep .select .icon-cross
  width: 10px
  height: 10px
</style>
