<template>
  <div class="">
    <HeaderMain />
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  
  created() {
    this.$store.dispatch('fetchNewNotifications')
  }
};
</script>

<style lang="sass">
.hotel_title_container
  .star_icons svg
    height: 28px

.vue-star-rating span.vue-star-rating-star
  display: flex
</style>
