<template>
  <div class="profile__wrapper">
    <h4 class="title-h4 profile__title">{{ $t('notification_form.title') }}</h4>
    <div class="profile__block">
      <TextField
        id="notification-subject"
        v-model="notification.subject"
        :placeholder="$t('notification_form.subject_placeholder')"
      />
      <span v-if="errors.subject" class="error-message">
        {{ $t('notification_form.errors.subject_required') }}
      </span>

      <TextEditor
        id="notification-body"
        v-model="notification.message"
        :placeholder="$t('notification_form.body_placeholder')"
      />
      <span v-if="errors.body" class="error-message">
        {{ $t('notification_form.errors.body_required') }}
      </span>

      <div class="notification-form__checkboxes">
        <div class="from-group" v-for="(item, i) in recepients_groups" :key="i">
          <label class="checkbox-label">
          <Checkbox
            v-model="notification.recepients_groups"
            :value="item"
          />
          {{ $t(`notification_form.recepients.${item}`) }}
        </label>
        </div>
      </div>

      <Button class="send__btn" @click.native="validateAndSend">
        {{ $t('notification_form.send_button') }}
      </Button>
    </div>
  </div>
</template>

<script>
import TextEditor from "@/elements/TextEditor.vue";

export default {
  name: 'NotificationCreate',

  components: { TextEditor },

  data() {
    return {
      recepients_groups: [
        'tour_operator',
        'hotel',
        'certification_agency',
      ],
      notification: {
        subject: '',
        message: '',
        recepients_groups: [],
      },
      errors: {
        subject: false,
        message: false,
      },
    };
  },
  
  methods: {
    async validateAndSend() {
      this.errors.subject = !this.notification.subject.trim();
      this.errors.message = !this.notification.message.trim();

      if (this.errors.subject || this.errors.message) {
        return;
      }

      this.$store.dispatch('createDartNotification', this.notification)
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 4px;
}

.notification-form__checkboxes {
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0;
  margin: 2rem 0 4rem 0;
  flex-wrap: wrap;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
