<template>
  <div class="auth__block">
    <h1 class="title-h2 auth__title">
      <i18n path="main.sign_in_to_account">
        <br />
      </i18n>
    </h1>
    <div class="auth__fields-wrapper">
      <EuKey/>
    </div>
  </div>
</template>

<script>
import EuKey from '@/elements/euKey/EuKey.vue'
import TextField from '@/elements/TextField.vue'

export default {
  components: {
    EuKey,
  },
  mixins: [TextField],
  data() {
    return {
      
    }
  },

  created() {
    this.$store.commit('SET_CURRENT_FORM_TYPE', 'auth')
  },

  methods: {

  },
}
</script>

<style lang="sass" scoped>
.auth__email
  margin-bottom: 4rem

.auth__forgot_pass
  text-decoration: underline
  cursor: pointer
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  -o-user-select: none
  user-select: none
</style>

