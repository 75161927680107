<template>
  <div class="main__content">
    <div class="table__header_bar">
      <div>
        <InputSearch isFilter @detectSearchValue="searchTableHandler" />
      </div>
      <div class="btn-wrapper">
        <Button
          isControl
          @click.native="createNotification"
        >
          {{ $t('notification_form.btn_create') }}
        </Button>
        <div class="tooltip-wrapper">
          <Tooltip
            :text="$t('tooltip.request_is_send_in_cur_reriod')"
            position="top"
            :is-dynamic="true"
          >
            <div class="tooltip-content" />
          </Tooltip>
        </div>
      </div>
    </div>
    <Table
      v-if="getTableOfDartNotifications.table"
      :content="getTableOfDartNotifications.table.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import { mapGetters } from 'vuex';
import InputSearch from '@/elements/InputSearch';
export default {
  components: {
    InputSearch,
    Table,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      'getTableOfDartNotifications',
    ]),
  },

  created() {
    this.$store.dispatch('getTableOfDartNotifications');
  },

  methods: {
    createNotification() {
      this.$router.push({
        name: 'dart_notifications_create',
      });
    },

    tableHandler() {
      this.$store.dispatch('getTableOfDartNotifications');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfDartNotifications');
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
