<template>
  <div class="profile__wrapper">
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="`${item.column_type}`"
        >
          <text-field
            v-if="item.name === 'notes'"
            :id="item.name"
            v-model="getCurUserInfo[item.name]"
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :stars-read-only="true"
            :is-icon="item.type === 'text_icon'"
            :value-mask="item.mask"
            is-disabled
            :isIcon="item.name === 'notes' && !!getCurUserInfo[item.name]"
            :icon-name="
              getCurUserInfo[item.name] === 'valid_certificate'
                ? 'actual'
                : 'error-check'
            "
            isTranslate
            translateFrom="profile"
          />
          <text-field
            v-else
            :id="item.name"
            v-model="getCurUserInfo[item.name]"
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :stars-read-only="true"
            :is-icon="item.type === 'text_icon'"
            :value-mask="item.mask"
            is-disabled
            :isIcon="item.name === 'notes'"
            icon-name="actual"
          />
        </div>
      </div>
    </div>
    <h4 class="title-h4 profile__title">
      {{ $t('profile.user_qr_code') }}
    </h4>
    <div class="profile__qr-code" v-html="getQrCode" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { convertDateToCurrentTimeZone } from '@/library/time';
import profile from '@/models/hotel/profile';
export default {
  data() {
    return {};
  },

  created() {
    this.$store.dispatch('getUserAccessAdmin', {
      user_uuid: this.$route.params.id,
    });
  },

  computed: {
    ...mapGetters(['getUserDetailsAccessAdmin']),
    getQrCode() {
      return this.getUserDetailsAccessAdmin?.role.qr_code;
    },
    getModel() {
      return profile;
    },

    getCurUserInfo() {
      let obj = {};
      let data = this.getUserDetailsAccessAdmin?.role;
      if (!!data) {
        for (let key in data) {
          let val = !!data[key] ? data[key] : '-';
          if (key === 'star_category' && !!!data[key]) {
            val = 0;
          }
          obj[key] = `${val}`;
        }

        obj.title = !!data.certificate_history?.title
          ? data.certificate_history?.title
          : '-';

        obj.population_item = !!data.settlement?.public_name
          ? data.settlement.public_name
          : '-';

        obj.region = !!data.state?.public_name ? data.state.public_name : '-';

        obj.district = !!data.district?.public_name
          ? data.district.public_name
          : '-';

        obj.katottg = !!data.settlement?.katottg
          ? data.settlement.katottg
          : '-';

        obj.certificate_series = !!data.certificate_history?.certificate_series
          ? data.certificate_history.certificate_series
          : '-';

        obj.certificate_number = !!data.certificate_history?.certificate_number
          ? `${data.certificate_history.certificate_number}`
          : '-';

        obj.certificate_validity = !!data.certificate_history
          ?.certificate_validity
          ? data.certificate_history.certificate_validity
          : '-';

        obj.certificate_term_end = !!data.certificate_history
          ?.certificate_term_end
          ? convertDateToCurrentTimeZone(
              data.certificate_history.certificate_term_end
            ).date
          : '-';

        obj.certificate_term_start = !!data.certificate_history
          ?.certificate_term_start
          ? convertDateToCurrentTimeZone(
              data.certificate_history.certificate_term_start
            ).date
          : '-';

        obj.notes = data.certificate_history.notes;
      }

      return obj;
    },
  },
};
</script>
