import { Axios, API_VERSION } from '@/api/config';

// export const getAuthUserInfo = () => {
//   const url = `${API_VERSION}/authorization/user`;
//   return Axios.get(url);
// };

export const getTableOfCategorizationsSecretary = (data) => {
  const url = `${API_VERSION}/secretary-of-the-commission/hotels/categorization-applications/table`;
  return Axios.get(url, { params: data });
};

export const getCategorizationSecretary = (uuid) => {
  const url = `${API_VERSION}/secretary-of-the-commission/hotels/categorization-applications?uuid=${uuid}`;
  return Axios.get(url);
};

export const getCommentsSecretary = (uuid) => {
  const url = `${API_VERSION}/secretary-of-the-commission/hotels/categorization-applications/comments?categorization_application_uuid=${uuid}`;
  return Axios.get(url);
};

export const setVotingDateSecretary = (data) => {
  const url = `${API_VERSION}/secretary-of-the-commission/hotels/categorization-applications/set/voting-date`;
  return Axios.post(url, data);
};

export const signVotingDateSecretary = (data) => {
  // const url = `${API_VERSION}/secretary-of-the-commission/hotels/categorization-applications/voting-date/sign?uuid=${data.categorization_application_uuid}`;
  const url = `${API_VERSION}/secretary-of-the-commission/hotels/categorization-applications/voting-date/sign`;
  return Axios.post(url, data);
};

export const getTableOfVotingHistorySecretary = (data) => {
  const url = `${API_VERSION}/secretary-of-the-commission/hotels/history-voting/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfVotingSecretary = (data) => {
  const url = `${API_VERSION}/secretary-of-the-commission/hotels/voting/table`;
  return Axios.get(url, { params: data });
};

export const addCommentSecretaryOfTheCommission = (data) => {
  const url = `${API_VERSION}/secretary-of-the-commission/hotels/categorization-applications/comments`;
  return Axios.post(url, data);
};

export const confirmVoteSecretary = (payload) => {
  const url = `${API_VERSION}/secretary-of-the-commission/hotels/voting/to-vote`;
  return Axios.post(url, payload);
};
