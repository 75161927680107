import { Axios, API_VERSION } from '@/api/config';
import axios from 'axios';

export const getRegistryTableData = (data) => {
  const url = `${API_VERSION}/web-site/registry/table`;
  return Axios.get(url, { params: data });
};

export const getSearchResults = (param) => {
  const url = `${API_VERSION}/web-site/registry/search/basic?query=${param}`;
  return Axios.get(url);
};

// ========== In General File ========== //
export const getAuthUser = (params = {}) => {
  const url = `${API_VERSION}/authorization/user`;
  return Axios.get(url, {
    params: params,
  });
};

// ========== End In General File ========== //

export const getNewsData = () => {
  const url = `${API_VERSION}/web-site/news`;
  return Axios.get(url);
};

// export const getSearchResultsDetails = (type, id) => {
//   const url = `${API_VERSION}/web-site/registry/std/${type}?uuid=${id}`;
//   return Axios.get(url);
// };

export const getLatestNewsData = () => {
  const url = `${API_VERSION}/web-site/news/recent`;
  return Axios.get(url);
};

// ========== In General File ========== //
export const saveECP = (data) => {
  const url = `${API_VERSION}/authorization/set-ecp`;
  return Axios.post(url, data);
};

export const removeECP = (data) => {
  const url = `${API_VERSION}/authorization/ecp`;
  return Axios.delete(url, data);
};
// ========== End In General File ========== //

export const getShortDataOfVoting = (uuid) => {
  const url = `${API_VERSION}/voting?voting_uuid=${uuid}`;
  return Axios.get(url);
};

export const getTableOfParticipants = (payload) => {
  const url = `${API_VERSION}/voting/participants/table`;
  return Axios.get(url, { params: payload });
};

export const signVotingResult = (payload) => {
  // const url = `${API_VERSION}/voting/sign-voting-result?voting_uuid=${payload.voting_uuid}&raw_sign=${payload.raw_sign}`;
  const url = `${API_VERSION}/voting/sign-voting-result`;
  return Axios.post(url, payload);
};

export const getLocationState = (cancelToken) => {
  const url = `${API_VERSION}/core/geo/states`;
  return Axios.get(url, { cancelToken });
};

export const getLocationDistricts = ({ state_id, cancelToken }) => {
  const url = `${API_VERSION}/core/geo/districts`;
  return Axios.get(url, { params: { state_id }, cancelToken });
};

export const getLocationSettlement = ({ district_id, cancelToken }) => {
  const url = `${API_VERSION}/core/geo/settlement`;
  return Axios.get(url, { params: { district_id }, cancelToken });
};

export const getContinents = () => {
  const url = `${API_VERSION}/core/geo/continents`;
  return Axios.get(url);
};

export const getCountries = (data) => {
  const url = `${API_VERSION}/core/geo/countries`;
  return Axios.get(url, { params: data });
};

export const getCertificateData = (uuid) => {
  const url = `${API_VERSION}/voting/certificate/initial-data-about-certificate`;
  return Axios.get(url, { params: { categorization_application_uuid: uuid } });
};

export const sendCertificateData = (data) => {
  const url = `${API_VERSION}/voting/certificate/save-data-about-certificate`;
  return Axios(url, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'POST',
    data: data,
  });
}

export const getNotifications = () => {
  const url = `${API_VERSION}/notifications`;
  return Axios.get(url);
};

export const getNewNotifications = () => {
  const url = `${API_VERSION}/notifications/new`;
  return Axios.get(url);
};

export const setNotificationsState = () => {
  const url = `${API_VERSION}/notifications/state`;
  return Axios.put(url);
};

// export { cancelTokenSource }
