<template>
  <div class="profile__wrapper">
    <div v-if="notifications.length > 0">
      <div
        v-for="notification in notifications"
        :key="notification.uuid"
        class="info-notification mt-6"
      >
        <template>
          <h4 class="info-notification__title">
            <svg
              v-if="notification.new"
              xmlns="http://www.w3.org/2000/svg"
              class="info-notification__ico svg-icon icon-info"
            >
              <use xlink:href="/img/icons.a12b6041.svg#info" />
            </svg>
            {{ notification.subject }}
          </h4>
          <div v-html="notification.message"></div>
        </template>
        <small class="notification__date">
          {{ formatDate(notification.created_at) }}
        </small>
      </div>
    </div>
    <div v-else class="notifications__empty">
      <div data-v-1da305af="" class="table__no-data" bis_skin_checked="1">
        {{ $t('main.no_notifications') }}
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['notifications', 'getNewNotifications']),
  },

  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(date).toLocaleDateString(this.$i18n.locale, options)
    },
  },

  created() {
    this.$store.dispatch('fetchNotifications')
    this.$store.dispatch('setNotificationsState')
  },
}
</script>

<style lang="sass">
.info-notification
  p
    text-indent: initial
</style>