<template>
  <div :class="{ 'menu-arrows': showNavArrows }" class="menu">
    <div
      v-if="showNavArrows"
      class="arrow__wrapper arrow__wrapper-left"
      :class="{ arrow__wrapper_disabled: navPosition == 0 }"
      @click="moveRight"
    >
      <svg-icon
        :class="{ 'arrow-disabled': navPosition == 0 }"
        class="icon-arrow-nav"
        name="arrow_nav_left"
      />
    </div>
    <div
      v-if="showNavArrows"
      class="arrow__wrapper arrow__wrapper-right"
      @click="moveLeft"
    >
      <svg-icon
        class="icon-arrow-nav"
        :class="{ 'arrow-disabled': navPosition == stopScrollPosition }"
        name="arrow_nav_right"
      />
    </div>

    <div
      class="nav__wrapper"
      :class="{ 'nav__wrapper_no-arrow': !showNavArrows }"
    >
      <ul class="menu__list" :class="{ 'sandwich-open': isSandwichOpen }">

        <li
          v-for="(item, index) in getCurrentMenu"
          :id="index"
          :key="item.title"
          @click="closeSandwichMenu"
        >

          <router-link
            class="menu__item"
            :class="{
              menu__item_active: currentRouteName.includes(item.rooth_name),
            }"
            :to="{ name: item.path_name }"
          >
            <h4
              v-if="item.name === 'notifications' && getNewNotifications.length > 0"
              class="info-notification__title"
            >
              <span class="notification-badge">{{ getNewNotifications.length }}</span>
              {{ $t(`sections.${item.name}`) }}
            </h4>
            <h4 v-else>
              {{ $t(`sections.${item.name}`) }}
            </h4>

          </router-link>
        </li>
        <li class="menu__item-logout">
          <Button
            transparent
            iconName="exit"
            isRightIcon
            @click.native="$store.dispatch('userLogout')"
          >
            {{ $t('main.exit') }}
          </Button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: { isSandwichOpen: { type: Boolean, default: false } },
  data() {
    return {
      navPosition: 0,
      offsetArray: [],
      currentNavPosition: 0,
      showNavArrows: false,
      stopScrollPosition: 4,
      test: false,
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentMenu',
      'getNewNotifications'
    ]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    isSandwichOpen: function () {
      if (this.isSandwichOpen) {
        document.documentElement.style.overflow = 'hidden';
        return;
      }
      document.documentElement.style.overflow = 'auto';
    },
  },
  mounted() {
    this.getCurrentMenu.forEach((element, index) => {
      const liElement = document.getElementById(index);
      this.offsetArray.push(liElement.clientWidth + 40);
    });

    const menu = document.querySelector('.header__nav-menu');
    const observerMenu = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (
          entry.borderBoxSize[0].inlineSize <=
          this.offsetArray.reduce((partial_sum, a) => partial_sum + a, 0)
        ) {
          this.showNavArrows = true;
        } else {
          this.showNavArrows = false;
        }
      });
    });
    observerMenu.observe(menu);
  },

  methods: {
    moveLeft() {
      if (this.navPosition != this.stopScrollPosition) {
        let translateValue =
          this.currentNavPosition + this.offsetArray[this.navPosition];
        let translateStyle = 'translateX(-' + translateValue + 'px';
        document.querySelector('.menu__list').style.transform = translateStyle;
        this.currentNavPosition = translateValue;
        this.navPosition += 1;
      }
    },
    moveRight() {
      if (this.navPosition != 0) {
        let translateValue =
          this.currentNavPosition - this.offsetArray[this.navPosition - 1];
        let translateStyle = 'translateX(-' + translateValue + 'px';
        document.querySelector('.menu__list').style.transform = translateStyle;
        document.querySelector('.menu__list').style.transition = 'all 0.3s';
        this.currentNavPosition = translateValue;
        this.navPosition -= 1;
      }
    },
    closeSandwichMenu() {
      this.$store.commit('SHOW_NOTIFICATION', false);
      this.$emit('closeSandwichMenu');
    },
    clearLSPath() {
      removeFromLS('roothName');
    },
  },
};
</script>

<style lang="sass" scoped>
.menu
  position: relative

.menu-arrows
  padding: 0 2rem

.nav__wrapper
  overflow-x: hidden
  position: relative
  // padding-left: 3rem
  // padding-right: 3rem
  &:not(.nav__wrapper_no-arrow)
    &::after
      width: 5%
      position: absolute
      bottom: 0
      right: -1%
      height: 100%
      content: ""
      background: linear-gradient(to left, rgba(250,248,233, 1) 0%, rgba(250,248,233, 0) 100%)
    &::before
      width: 2%
      position: absolute
      bottom: 0
      left: -1%
      height: 100%
      content: ""
      background: linear-gradient(to right, rgba(228,240,255, 1) 0%, rgba(228,240,255, 0) 100%)
      z-index: 1

.menu__list
  display: flex
  gap: 4rem
  white-space: nowrap
  bottom: 1rem
  // transition: top 0.8s

.menu__item
  color: $gray_notification
  display: block
  @include s
    width: 100%
    position: relative
    padding-left: 2.4rem
  @include xs
    padding-left: 1.6rem

.menu__item:hover, .menu__item.router-link-active, .menu__item_active
  color: $black

.menu__item.router-link-active , .menu__item_active
  border-bottom: 2px solid #000000
  width: 100%
  padding-bottom: 10px
  @include s
    border-bottom: none

.arrow__wrapper
  display: flex
  bottom: 0.5rem
  &:not(.arrow__wrapper_disabled)
    cursor: pointer
  @include s
    display: none

.arrow__wrapper-left
  position: absolute
  left: -2rem

.arrow__wrapper-right
  position: absolute
  right: -2rem

.arrow-disabled
  filter: invert(75%) sepia(24%) saturate(13%) hue-rotate(314deg) brightness(93%) contrast(90%)

.menu__list
  @include s
    padding-top: 2.4rem
    display: flex
    flex-direction: column
    justify-content: start
    gap: 0
    position: fixed
    background-color: white
    width: 100%
    height: 100%
    z-index: 100
    left: 0
    bottom: 0
    top: -100%
    transition: top .8s
    transition-timing-function: ease-in-out

.menu__item-logout
  display: none

.sandwich-menu
  margin-left: auto
  cursor: pointer
.sandwich-open
  transition: top .8s
  transition-timing-function: ease-in-out
  padding: 2.4rem 0
  display: flex
  flex-direction: column
  justify-content: start
  gap: 0
  background-color: white
  z-index: 102
  left: 0
  width: 100%
  @include s
    top: 12rem
    top: 0
    // border: solid 1px red
    height: calc( 100% - 12rem )
    transform: translateY(12rem)
  @include xs
    top: 6.8rem
    top: 0
    height: calc( 100% - 6.8rem )
    transform: translateY(6.8rem)

  .menu__item-logout
    display: flex
    justify-content: center
    margin-top: auto

  .menu__item
    padding-top: 12px
    padding-bottom: 12px

  .menu__item.router-link-active, .menu__item_active
    background-color: #F9F9F9

.sandwich-login
  margin-top: 6rem

.info-notification__title
  margin-bottom: 0

.notification-badge
  display: inline-flex
  align-items: center
  justify-content: center
  text-align: center
  color: #fff
  padding: 4px
  height: 22px
  min-width: 22px
  background: #000
  font-weight: 400
  border-radius: 50px
  font-size: 13px
  margin-right: .5rem
</style>
