import {
  getTableOfVotingPresiding,
  getCategorizationPresiding,
  getTableOfVotingHistoryPresiding,
  getCommentsPresiding,
  setVotingDatePresiding,
  signVotingDatePresiding,
  confirmVotePresiding,
  addCommentPresidingCommissioner,
  getReportsTablePresidingCommissioner,
} from '@/api/services/presiding_commiss.js';
import router from '@/router';
import VueI18n from '@/i18n';

export default {
  state: {
    tableOfCategorizationsPresiding: [],
    categorizationPresiding: [],
    tableOfVotingHistoryPresiding: [],
    commentsPresiding: [],
    needRout: true,
    tableOfReportsPresiding: null,
  },

  getters: {
    getTableOfVotingPresiding: (state) => state.tableOfCategorizationsPresiding,
    getTableOfVotingHistoryPresiding: (state) =>
      state.tableOfVotingHistoryPresiding,
    getCategorizationPresiding: (state) => state.categorizationPresiding,
    getCommentsPresiding: (state) => state.commentsPresiding,
    getTableOfReportsPresiding: (state) => state.tableOfReportsPresiding,

    isRouting: (state) => state.needRout,
  },

  mutations: {
    SET_CATEGORIZATION_TABLE_PRESIDING(state, data) {
      state.tableOfCategorizationsPresiding = data;
    },
    SET_TABLE_OF_VOTING_PRESIDING(state, data) {
      state.tableOfVotingHistoryPresiding = data;
    },
    SET_CATEGORIZATION_PRESIDING(state, data) {
      state.categorizationPresiding = data;
    },
    SET_COMMENTS_PRESIDING(state, data) {
      state.commentsPresiding = data;
    },

    SET_NEED_ROUT(state, value) {
      state.needRout = value;
    },

    SET_TOUR_OPERATOR_TABLE_PRESIDING(state, data) {
      state.tableOfReportsPresiding = data;
    },
  },

  actions: {
    async getTableOfVotingPresiding({ commit, getters }) {
      try {
        let res;
        res = await getTableOfVotingPresiding(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_CATEGORIZATION_TABLE_PRESIDING', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfCategorizations',
          e.response.data.error
        );
      }
    },

    async getTableOfVotingHistoryPresiding({ commit, getters }) {
      try {
        let res;
        res = await getTableOfVotingHistoryPresiding(getters.getTableSortData);
        if (res.status === 200) {
          commit('SET_TABLE_OF_VOTING_PRESIDING', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfVotingHistoryPresiding',
          e.response.data.error
        );
      }
    },

    async getCategorizationPresiding({ commit }, uuid) {
      let res;
      try {
        res = await getCategorizationPresiding(uuid);
        commit(
          'SET_CATEGORIZATION_PRESIDING',
          res.data.data.categorization_application
        );
      } catch (e) {
        console.log('>>> getCategorizationPresiding error', e);
      }
    },

    async getCommentsPresiding({ commit }, uuid) {
      let res;
      try {
        res = await getCommentsPresiding(uuid);
        commit('SET_COMMENTS_PRESIDING', res.data.data.criterias);
      } catch (e) {
        console.log('>>> getCommentsPresiding error', e);
      }
    },

    async setVotingDatePresiding({ commit }, payload) {
      const { data, isSign } = payload;
      let res;
      try {
        res = await setVotingDatePresiding(data);
        if (res.status === 200) {
          if (!isSign) {
            commit('SHOW_NOTIFICATION', true);
            commit(
              'GET_RESPONSE_MESSAGE',
              VueI18n.t('notification.data_is_saved')
            );
            router.push({ name: 'presiding-commissioner_votes-list' });
          } else {
            commit('SET_NEED_ROUT', true);
            commit('modal/SET_MODAL', {
              state: true,
              name: 'add-key',
              size: 'l',
            });
          }
        }
      } catch (e) {
        console.log('>>> setVotingDatePresiding error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response?.data?.message);
        commit('GET_IS_ERROR', true);
      }
    },
    async signVotingDatePresiding({ commit, dispatch, getters }, payload) {
      let res;
      commit('SET_RQUEST_LOADED', false);
      try {
        res = await signVotingDatePresiding({ ...payload });
        if (res.status === 200) {
          commit('SET_RQUEST_LOADED', true);
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('table.signed'));
          if (getters.isRouting) {
            router.push({ name: 'presiding-commissioner_votes-list' });
          } else {
            dispatch('getTableOfVotingPresiding');
          }
        }
      } catch (e) {
        console.log('>>> signVotingDatePresiding error', e.response.data);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.errors.ecp);
        commit('GET_IS_ERROR', true);
      }
    },

    async signRequestFromTable({ commit }, uuid) {
      commit('SET_CURRENT_FORM_TYPE', 'categorization-presiding-commissioner');
      commit('SET_DATA_TO_SIGN', uuid);
      commit('SET_NEED_ROUT', false);

      commit('modal/SET_MODAL', {
        state: true,
        name: 'add-key',
        size: 'l',
      });
      // signVotingDatePresiding(nb)
    },

    async confirmVotePresiding({ commit }, payload) {
      let res;
      try {
        res = await confirmVotePresiding(payload);
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('table.vote'));
          router.push({
            name: 'presiding-commissioner_voting-results',
            params: {
              id: payload.voting_uuid,
            },
          });
        }
        // router.push({
        //   path: `voting-results/${payload.voting_uuid}`,
        // });
      } catch (e) {
        console.log('>>> error confirmVote', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response?.data?.message);
        commit('GET_IS_ERROR', true);
        // Delete after Boris fix of categorization attributes
        // router.push({
        //   path: `voting-results/${payload.voting_uuid}`,
        // });
      }
    },

    async addCommentPresidingCommissioner({ commit }, payload) {
      let res;
      const {
        categorization_application_uuid,
        criterion_uuid,
        comment: message,
      } = payload;
      try {
        res = await addCommentPresidingCommissioner({
          categorization_application_uuid,
          criterion_uuid,
          message,
        });
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.sent'));

          return res.data.data.result;
        }
      } catch (e) {
        console.log('>>> getCommentsPresiding error', e);
      }
    },

    async getReportsTablePresidingCommissioner({ commit, getters }) {
      try {
        let res;
        res = await getReportsTablePresidingCommissioner(
          getters.getTableSortData
        );
        if (res.status === 200) {
          commit('SET_TOUR_OPERATOR_TABLE_PRESIDING', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log('>>> error getReportsTablePresidingCommissioner', e);
      }
    },
  },
};
