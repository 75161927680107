import {
  uploadDocuments,
  uploadCertificate,
} from '@/api/services/voting';

import VueI18n from '@/i18n';

export default {
  state: {
    uploadedCertificate: null,
  },

  getters: {
    getUploadedCertificate: (state) => state.uploadedCertificate,
  },

  mutations: {
    SET_UPLOADED_CERTIFICATE(state, payload) {
      state.uploadedCertificate = payload
    }
  },

  actions: {
    async votingResultUploadDocuments({ commit }, payload) {
      let res;
      try {
        const formData = new FormData

        formData.append('categorization_application_uuid', payload.categorization_application_uuid)

        payload.documents.forEach((file, i) => {
          formData.append(`documents[${i}]`, file)
        })

        res = await uploadDocuments(formData);
        if (res.status === 200) {
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
        }
      } catch (e) {
        console.log('>>>> votingResultUploadDocuments error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async addVotingResultCertificate({ commit }, payload) {
      commit('SET_UPLOADED_CERTIFICATE', payload)
    }
  },
};
