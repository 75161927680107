import { Axios, API_VERSION, config } from '@/api/config';

export const uploadDocuments = (data) => {
  const url = `${API_VERSION}/voting/upload-documents`;
  return Axios(url, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'POST',
    data: data,
  });
}
