<template>
  <div class="">
    <HeaderMain />
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {
    this.$store.dispatch('fetchNewNotifications')
  }
};
</script>
