<template>
  <div class="profile__wrapper">
    <h4 class="title-h4 profile__title">{{ $t('notification_form.notification_title') }}</h4>
    <div class="profile__block notification-item">
      <div class="info-row">
        <h3>{{ $t(`notification_form.subject`) }}: {{ getDartNotification.subject }}</h3>
      </div>
      <div class="info-row">
        <h3>{{ $t(`notification_form.created_at`) }}:</h3>
        <div class="notification-item__content">
          <p>{{ getDartNotification.created_at }}</p>
        </div>
      </div>
      <div class="info-row">
        <h3>{{ $t(`notification_form.to_recepients_groups`) }}:</h3>
        <div class="notification-item__content">
          <ul>
            <li v-for="(item, i) in getDartNotification.recepients_groups" :key="i">{{ $t(`notification_form.recepients.${item}`) }}</li>
          </ul>
        </div>
      </div>
      <div class="info-row">
        <h3>{{ $t(`notification_form.send_from`) }}:</h3>
        <div class="notification-item__content">
          <p>{{ getDartNotification.sender_name }}</p>
        </div>
      </div>
      <div class="info-row">
        <h3>{{ $t(`notification_form.message`) }}:</h3>
        <div class="notification-item__content" v-html="getDartNotification.message"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NotificationShow',

  computed: {
    ...mapGetters([
      'getDartNotification',
    ]),
  },

  async created() {
    await this.$store.dispatch('getDartNotification', this.$route.params.id);
  },
}
</script>

<style lang="sass" scoped>
.notification-item
  .info-row
    margin-bottom: 4rem
    
  h3
    margin-bottom: 1rem
    font-size: 1.85rem
    font-weight: 700

  &__content
    ul
      list-style: initial
      margin-left: 2rem
</style>
