export default {
  group: [
    {
      name: 'personal_data',
      fields: [
        {
          name: 'position',
          type: 'select',
          mask: null,
          isObjectType: true,
          titleKey: 'name',
          valueKey: 'value',
          valueToReturn: 'object',
          options: [
            {
              value: 'commissioner',
              name: 'Член Комісії',
            },
            {
              value: 'presiding_commissioner',
              name: 'Голова комісії категоризації',
            },
            {
              value: 'deputy_presiding_commissioner',
              name: 'Заступник голови комісії категоризації',
            },
            {
              value: 'secretary_of_the_commission',
              name: 'Секретар комісії',
            },
          ],
          column_type: 'full-row',
        },
        {
          name: 'full_name',
          type: 'text',
          mask: null,
          column_type: 'full-row',
        },
        {
          name: 'edrpou',
          type: 'number',
          mask: null,
          column_type: 'full-row',
        },
        {
          name: 'date_of_birth',
          type: 'date',
          mask: null,
          column_type: 'one-third-row',
        },
        {
          name: 'term_of_office_start',
          type: 'date',
          mask: null,
          column_type: 'one-third-row',
        },
        {
          name: 'term_of_office_end',
          type: 'date',
          mask: null,
          column_type: 'one-third-row',
        },
        {
          name: 'work_place',
          type: 'text',
          mask: null,
          column_type: 'full-row',
        },
        {
          name: 'identification_number',
          type: 'text',
          mask: 'integer',
          column_type: 'full-row',
        },
        {
          name: 'department',
          type: 'text',
          mask: null,
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'contact_data',
      fields: [
        {
          name: 'working_phone_number',
          type: 'text',
          mask: 'phone',
          column_type: 'one-third-row',
        },
        {
          name: 'working_email',
          type: 'text',
          mask: 'email',
          column_type: 'one-third-row',
        },
      ],
    },
  ],
};
