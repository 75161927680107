<template>
  <div>
    <label
      class="checkbox checkbox__form"
      :class="{
        checkbox__form_ckecked: isChecked,
        checkbox__form_disabled: disabled,
      }"
    >
      <input
        v-model="checkedItems"
        type="checkbox"
        class="checkbox__input"
        :checked="isChecked"
        :value="value"
        :disabled="disabled"
      />
      <div class="checkbox__span">
        <svg-icon v-if="isChecked" name="check" class="checkbox__ico" />
      </div>
    </label>
    <!-- {{ value.id }} -->
    <!-- {{ chekedIds }} -->
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    value: {
      type: [String, Number, Object, Boolean],
      required: false,
      default: '',
    },
    checked: {
      type: [Array, String, Boolean],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },
  computed: {
    isChecked() {
      if(Array.isArray(this.checked)) {
        if(this.checkedItems.hasOwnProperty('id') && this.chekedIds && this.chekedIds.includes(this.value.id)) {
          return true
        } else {
          return this.checked.includes(this.value)
        }
      } else {
        return this.checked
      }

      // return Array.isArray(this.checked)
      //   ? this.chekedIds.includes(this.value.id)
      //   : this.checked
    },

    chekedIds() {
      return this.checkedItems.map((el) => el.id)
    },

    checkedItems: {
      get() {
        return this.checked
      },
      set(val) {
        this.$emit('change', val)
      },
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>
.checkbox__form
  display: block
  width: 15px
  min-width: 15px
  height: 15px
  box-sizing: content-box
  position: relative
  border: solid 2px $gray
  border-radius: 5px
  margin-right: 10px
  transition: all 0.15s
  &:not(.checkbox__form_disabled)
    &:hover
      border-color: $black
      transition: all 0.25s
      cursor: pointer

  // @include xxs
  //   display: inline
  //   position: absolute
  //   top: -2px
  //   left: 27px
  //   font-size: 10px
  //   line-height: 22px
  //   color: $gray

.checkbox__input
  position: absolute
  z-index: -1
  opacity: 0
  user-select: none

.checkbox__ico
  width: 10px
  height: 10px
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  fill: $black

.checkbox__form_ckecked
  background: $black
  border-color: $black
  .checkbox__ico
    fill: $white
</style>

