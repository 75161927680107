import { Axios, API_VERSION } from '@/api/config';
import {config} from "../config";

// export const getAuthUserInfo = () => {
//   const url = `${API_VERSION}/authorization/user`;
//   return Axios.get(url);
// };

export const getCategorizationFormStructure = (data) => {
  const { uuid, lang } = data;
  let categorizationData = !!uuid
    ? `?categorization_application_uuid=${uuid}`
    : '';

  const url = `${API_VERSION}/dart/hotels/categorization/form/structure${categorizationData}`;

  config.headers['Accept-Language'] = lang;


  return Axios.get(url, config);
};

export const searchHotels = (q) => {
  const url = `${API_VERSION}/dart/hotels/search?q=${q}`;
  return Axios.get(url);
};

export const getTableOfCategorizationsDart = (data) => {
  const url = `${API_VERSION}/dart/hotels/categorization-applications/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfVotingHistoryDart = (data) => {
  const url = `${API_VERSION}/dart/hotels/history-voting/table`;
  return Axios.get(url, { params: data });
};

export const getCategorizationDart = (uuid) => {
  const url = `${API_VERSION}/dart/hotels/categorization-applications?uuid=${uuid}`;
  return Axios.get(url);
};

export const sendForImprovement = (uuid) => {
  const url = `${API_VERSION}/dart/hotels/categorization-applications/return-to-refinement?uuid=${uuid}`;
  return Axios.post(url);
};

export const sendToComission = (uuid) => {
  const url = `${API_VERSION}/dart/hotels/categorization-applications/transfer-to-commission?uuid=${uuid}`;
  return Axios.post(url);
};

export const getCommentsDart = (uuid) => {
  const url = `${API_VERSION}/dart/hotels/categorization-applications/comments?categorization_application_uuid=${uuid}`;
  return Axios.get(url);
};

export const leaveCommentDart = (
  criterion_uuid,
  categorization_application_uuid,
  comment
) => {
  const url = `${API_VERSION}/dart/hotels/categorization-applications/comments?criterion_uuid=${criterion_uuid}&categorization_application_uuid=${categorization_application_uuid}&message=${comment}`;
  return Axios.post(url);
};

export const addCommentReportDart = (criterion_uuid, report_uuid, comment) => {
  const url = `${API_VERSION}/dart/tour-operators/reports/comments/add?criterion_uuid=${criterion_uuid}&report_uuid=${report_uuid}&message=${comment}`;
  return Axios.post(url);
};

export const saveECP = (data) => {
  const url = `${API_VERSION}/authorization/set-ecp`;
  return Axios.post(url, data);
};

export const existingCategorizationСomments = (data) => {
  const url = `${API_VERSION}/dart/hotels/categorization-applications/return-to-refinement/flag-indicating-whether-dart-added-comment`;
  return Axios.get(url, { params: data });
};

export const existingReportСomments = (data) => {
  const url = `${API_VERSION}/dart/tour-operators/reports/actions/return-to-refinement/flag-indicating-whether-dart-added-comment`;
  return Axios.get(url, { params: data });
};

export const getTableOfReportsDart = (data) => {
  const url = `${API_VERSION}/dart/tour-operators/reports/table`;
  return Axios.get(url, { params: data });
};

export const sendReportForImprovementDart = (data) => {
  const url = `${API_VERSION}/dart/tour-operators/reports/actions/return-to-refinement`;
  return Axios.post(url, data);
};

export const approveTourOperatorsReport = (data) => {
  const url = `${API_VERSION}/dart/tour-operators/reports/actions/approve`;
  return Axios.post(url, data);
};

export const createNotification = async (mailData) => {
  const url = `${API_VERSION}/dart/notifications`;
  return Axios.post(url, mailData);
};

export const getDartNotifications = () => {
  const url = `${API_VERSION}/dart/notifications`;
  return Axios.get(url);
};

export const getDartNotification = (uuid) => {
  const url = `${API_VERSION}/dart/notifications/${uuid}`;
  return Axios.get(url);
};

export const createFullCategorizationRequestDart = (data) => {
  const url = `${API_VERSION}/dart/hotels/categorization/form/create/full`;
  return Axios.post(url, data);
};

export const addCategorizationRequestDraft = (data) => {
  const url = `${API_VERSION}/dart/hotels/categorization/form/create/as-draft`;
  return Axios.post(url, data);
};

export const deleteCategorizationRequest = (data) => {
  const url = `${API_VERSION}/dart/hotels/categorization/form/application`;
  return Axios.delete(url, { params: data });
};

export const getCategorizationDraft = (uuid) => {
  const url = `${API_VERSION}/dart/hotels/categorization/form/application?categorization_application_uuid=${uuid}`;
  return Axios.get(url);
};

export const editCategorizationRequestDraft = (data) => {
  const url = `${API_VERSION}/dart/hotels/categorization/form/application/edit`;
  return Axios.post(url, data);
};
