<template>
  <div class="main__content members">
    <div class="members__control">
      <div class="members__search-select">
        <InputSearch
          isFilter
          class="members__control-item members__control_search"
          @detectSearchValue="searchTableHandler"
        />
        <SelectField
          v-model="selectedCommissionMember"
          titleKey="title"
          valueToReturn="object"
          isObjectType
          :items="commissionMembersList"
          :placeholder="$t('main.select_role')"
          class="members__control-item members__control_select"
        />
      </div>
      <Button
        isControl
        iconName="plus"
        :disabled="!isCanCreateUser"
        @click.native="createCommissionerUserHandler"
      >
        {{ $t('sections.commissioner_to_register') }}
      </Button>
    </div>
    <Table
      v-if="!!selectedTable"
      :content="selectedTable"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueI18n from '@/i18n'
import Table from '@/elements/Table'
import SelectField from '@/elements/SelectField.vue'

export default {
  components: {
    Table,
    SelectField,
  },

  data() {
    return {
      commissionMembersList: [
        {
          name: 'PresidingCommissioner',
          title: VueI18n.t('sections.presiding_commissioner'),
        },
        {
          name: 'DeputyPresidingCommissioner',
          title: VueI18n.t('sections.deputy_presiding_commissioner'),
        },
        {
          name: 'SecretaryOfTheCommission',
          title: VueI18n.t('sections.secretary_of_the_commission'),
        },
        { name: 'Commissioner', title: VueI18n.t('sections.commissioner') },
      ],
    }
  },

  created() {
    this.getTable()
  },

  computed: {
    ...mapGetters(['getSelectedCommissionMember']),

    selectedCommissionMember: {
      get() {
        return this.getSelectedCommissionMember
      },
      set(val) {
        this.$store.commit('SET_SELECTED_COMMISSION_MEMBER', val)
        this.getTable()
      },
    },

    getTableMethodName() {
      if (!!this.getSelectedCommissionMember?.name) {
        return `getTableOf${this.getSelectedCommissionMember.name}AccessAdmin`
      }
    },

    selectedTable() {
      return this.$store.getters[
        `getTableOf${this.getSelectedCommissionMember.name}AccessAdmin`
      ]?.table?.items
    },

    isCanCreateUser() {
      return (
        !!this.getSelectedCommissionMember.name &&
        this.getSelectedCommissionMember.name !== 'Presiding'
      )
    },
  },

  methods: {
    createCommissionerUserHandler() {
      this.$router.push({
        name: 'access-administrator_commissioner-create-user',
        params: {
          activity: 'create',
        },
      })
    },
    getTable() {
      !!this.getTableMethodName && this.$store.dispatch(this.getTableMethodName)
    },

    tableHandler() {
      !!this.getTableMethodName && this.$store.dispatch(this.getTableMethodName)
      this.$store.commit('RESET_TABLE_SORT_DATA')
      this.$store.commit('SET_LAST_PAGE', null)
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val })
      !!this.getTableMethodName && this.$store.dispatch(this.getTableMethodName)
    },
  },
}
</script>

<style lang="sass" scoped>
.members__control
  display: flex
  justify-content: space-between
  padding: 5rem 0
  align-items: center
  position: relative
  z-index: 10
  @include s
    flex-direction: column-reverse
    align-items: start
    padding: 3rem 0

.members__search-select
  display: flex
  align-items: baseline
  @include s
    margin-top: 30px
  @include xs
    flex-direction: column
    margin-top: 10px
    margin-bottom: 40px

.members__control-item
  width: 300px

.members__control_search
  margin-right: 40px
  @include xs
    margin-right: 0

.members__control_select
  transform: translateY(3px)
  @include xs
    margin-top: 20px
</style>

