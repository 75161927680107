<template>
  <div class="profile__wrapper">
    <template v-if="structure">
      <div class="categorisation categorisation__wrapper">
        <div
          class="profile__section"
          :style="{ order: group.order_position }"
          v-for="(group, id_0) in structure"
          :key="`${group.uuid}${id_0}`"
        >
          <h4 class="title-h4 profile__title" v-if="!!group.title">
            {{ group.title }}
          </h4>
          <div
            class="categorisation__block"
            :style="{ order: subgroup.order_position }"
            v-for="(subgroup, id_1) in group.subgroups"
            :key="`${subgroup.uuid}${id_1}`"
          >
            <h5 class="title-h5 profile__subtitle" v-if="!!subgroup.title">
              {{ subgroup.title }}:
            </h5>
            <div
              :class="[
                subgroup.criterias[0].type === 'images'
                  ? 'image__grid'
                  : 'profile__grid',
              ]"
            >
              <div
                v-for="(item, id_2) in subgroup.criterias"
                :key="`${item.uuid}${id_2}`"
                :class="`${item.classnames}`"
                :style="{ order: item.order_position }"
                :id="item.uuid"
              >
                <text-field
                  v-if="['text', 'number', 'rating'].includes(item.type)"
                  v-model="item['value']"
                  :is-error="item.error == true"
                  :stars-read-only="editIsDisabled(item.uuid)"
                  :title="item.title"
                  :is-rate="item.type === 'rating'"
                  :is-icon="item.type === 'text_icon'"
                  :value-mask="item.mask"
                  :is-inactive="editIsDisabled(item.uuid)"
                  icon-name="actual"
                  :can-comment="canComment"
                  is-commentable
                  :uuid="item.uuid"
                />
                <FileUpload
                  v-else-if="['file', 'files'].includes(item.type)"
                  :id="item.uuid"
                  v-model="item['value']"
                  :title="item.title"
                  :is-multiple="item.type == 'files'"
                  :is-download-only="editIsDisabled(item.uuid)"
                  is-commentable
                  :uuid="item.uuid"
                  :can-comment="canComment"
                />
                <PhotoUpload
                  v-else-if="['images'].includes(item.type)"
                  :id="item.uuid"
                  :uuid="item.uuid"
                  v-model="item['value']"
                  :photos-uploaded="item['value']"
                  :title="item.title"
                  is-multiple
                  is-commentable
                  :is-disabled="editIsDisabled(item.uuid)"
                  :can-comment="canComment"
                />
                <div
                  :class="`${item.classnames}`"
                  v-else-if="item.type === 'switcher'"
                >
                  <Switcher
                    :uuid="item.uuid"
                    :can-comment="canComment"
                    :is-commentable="commentsVisible"
                    :is-inactive="true"
                    v-model="item.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="voteSchedule !== 'none'" class="voting-date__block">
        <div v-if="voteSchedule === 'edit'" class="voting-date__block">
          <h4 class="title-h4 profile__title">
            {{ $t('categorization.voting_date_title') }}
          </h4>
          <p class="subtitle">
            {{ $t('categorization.voting_date_subtitle') }}
          </p>
        </div>
        <div v-if="voteSchedule === 'view'" class="voting-date__block">
          <h4 class="title-h4 profile__title">
            {{ $t('categorization.voting_date_title_view') }}
          </h4>
          <p class="subtitle">
            {{ $t('categorization.voting_date_subtitle_view') }}
          </p>
        </div>
        <FileUpload
          v-model="uploadedSchedule"
          class="schedule__upload"
          :is-multiple="false"
          :valid-formats="['doc', 'docx', 'pdf', 'img', 'jpeg', 'jpg', 'png']"
          isFileFormat
          :is-download-only="voteSchedule == 'view'"
          :max-size-k-b="25000"
          :is-error="this.$v.uploadedSchedule.$error"
          @dropError="uploadedScheduleError = false"
        />
        <div class="">
          <DatePicker
            v-model="votingDate"
            :format="'DD-MM-YYYY'"
            value-type="YYYY-MM-DD HH:mm:ss"
            :lang="getDateConfig"
            :disabled="voteSchedule == 'view'"
            :disabled-date="disabledDates"
            :placeholder="$t(`categorization.voting_date_placeholder`)"
            @input="votingDateError = false"
          />
          <div v-if="this.$v.votingDate.$error" class="error-message">
            <template v-if="votingDateErrorText">
              {{ votingDateErrorText }}
            </template>
            <template v-else>
              {{ $t('error.required_field') }}
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <LoaderContent />
    </template>
    <div class="no-print">
      <ButtonsBlock
        :disabled="!isSelectValidDate"
        :disabledSign="!isUserHasEuKey"
        :disabledDownLoad="!isCertificate"
        :disabledAchive="!isAchive"
        :buttons="buttonsArray"
        :disabledVote="isAfterToday"
        @print="print()"
        @downloadCertificate="downloadCertificate()"
        @signAndSend="signAndSendHandler()"
        @sendToComissionHead="sendToComissionHeadHandler()"
        @preliminaryResults="preliminaryResults()"
        @downloadPDF="downloadPDF()"
        @vote="voteHandler()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import datePickerConfig from '@/library/date-picker-config';
import DatePicker from 'vue2-datepicker';
import { getCategorizationFormStructure } from '@/api/services/hotel.js';
import {
  checkIsSameDate,
  checkIsDateSameOrBeforeToday,
  checkIsDateAfterToday,
} from '@/library/time';
import FileUpload from '@/elements/FileUpload.vue';
import PhotoUpload from '@/elements/PhotoUpload.vue';
import ButtonsBlock from '@/elements/ButtonsBlock.vue';
import Switcher from '@/elements/Switcher.vue';
import LoaderContent from '@/elements/LoaderContent.vue';

export default {
  components: {
    FileUpload,
    PhotoUpload,
    DatePicker,
    ButtonsBlock,
    Switcher,
    LoaderContent,
  },
  data() {
    return {
      buttonsArray: [],
      commentsVisible: false,
      canComment: false,
      canEdit: '',
      voteSchedule: '',
      dataToSend: new FormData(),
      uploadedSchedule: [],
      votingDate: '',
      votingDateError: false,
      votingDateErrorText: '',
      structure: null,
    };
  },

  async created() {
    this.$store.commit('SET_CURRENT_FORM_TYPE', 'categorization-secretary'); // for key-sign flow
    await this.getCategorizationStructure();

    let categorizationRequestUUUID = this.$route.params.id;

    await this.$store.dispatch(
      'getCategorizationSecretary',
      categorizationRequestUUUID
    );

    this.statusModelHandler();

    if (this.commentsVisible) {
      await this.$store.dispatch(
        'getCommentsSecretary',
        categorizationRequestUUUID
      );
    }

    this.populateFormWithData();
    this.getScheduleData();
  },

  computed: {
    ...mapGetters([
      'getCategorizationSecretary',
      'getCommentsSecretary',
      'getStatusModel',
      'isUserHasEuKey',
    ]),

    prefilledCriteris() {
      let prefilledCriteris = {};
      this.getCategorizationSecretary.criteria.forEach((criteria) => {
        prefilledCriteris[criteria.categorization_criterion_uuid] = criteria;
      });
      return prefilledCriteris;
    },
    getDateConfig() {
      return datePickerConfig;
    },

    ifToday() {
      return checkIsSameDate(this.getCategorizationSecretary.voting_at);
    },

    ifBeforeOrToday() {
      return checkIsDateSameOrBeforeToday(
        this.getCategorizationSecretary?.voting_at
      );
    },

    isAfterToday() {
      return checkIsDateAfterToday(this.getCategorizationSecretary?.voting_at);
    },

    isSelectValidDate() {
      return checkIsDateAfterToday(this.votingDate);
    },

    isCertificate() {
      return !!this.getCategorizationSecretary?.certificate;
    },

    isAchive() {
      return !!this.getCategorizationSecretary?.archive;
    },
  },

  methods: {
    async getCategorizationStructure() {
      let data = { lang: 'uk', uuid: this.$route.params.id };
      try {
        let res;
        res = await getCategorizationFormStructure(data);
        if (res.status === 200) {
          this.structure = res.data.data.structure;
        }
      } catch (e) {
        console.log(
          '>>> error getCategorizationFormStructure',
          e.response.data.error
        );
      }
    },

    async signAndSendHandler() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.dataToSend.append('uuid', this.getCategorizationSecretary.uuid);
        this.dataToSend.append('voting_date', this.votingDate);
        this.dataToSend.append('schedule_attachment', this.uploadedSchedule[0]);

        // this.dataToSend.append(`voting_date`, '2022-04-28 20:00:00');

        this.$store.commit(
          'SET_DATA_TO_SIGN',
          this.getCategorizationSecretary.uuid
        );
        this.$store.dispatch('setVotingDateSecretary', {
          data: this.dataToSend,
          isSign: true,
        });
      }
    },

    sendToComissionHeadHandler() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.dataToSend.append('uuid', this.getCategorizationSecretary.uuid);
        this.dataToSend.append('voting_date', this.votingDate);
        this.dataToSend.append('schedule_attachment', this.uploadedSchedule[0]);
        this.$store.dispatch('setVotingDateSecretary', {
          data: this.dataToSend,
          isSign: false,
        });
      }
    },

    print() {
      window.print();
    },

    statusModelHandler() {
      this.getStatusModel.forEach((element) => {
        if (
          element.filing_status ==
            this.getCategorizationSecretary.filing_status &&
          element.review_status == this.getCategorizationSecretary.review_status
        ) {
          this.commentsVisible = element.comments_visible;
          this.canComment =
            element.can_comment ||
            (this.ifToday &&
              this.getCategorizationSecretary.review_status ==
                'waiting_for_voting');
          this.canEdit = element.edit;
          this.voteSchedule = element.schedule;

          if (Array.isArray(element.buttons)) {
            this.buttonsArray = element.buttons;
          } else {
            this.buttonsArray = this.ifBeforeOrToday
              ? [...element.buttons.buttonsDuringVoting]
              : [...element.buttons.buttonsBeforeVoting];
          }
        }
      });
    },

    editIsDisabled(uuid) {
      let editIsDisabled = false;
      switch (this.canEdit) {
        case 'can_edit_all_fields':
          editIsDisabled = false;
          break;
        case 'not_allowed':
          editIsDisabled = true;
          break;
        case 'can_edit_commented_fields':
          this.getCommentsHotel[uuid]
            ? (editIsDisabled = false)
            : (editIsDisabled = true);
          break;
      }
      return editIsDisabled;
    },

    preliminaryResults() {
      this.$router.push({
        path: `voting-results/${this.getCategorizationSecretary.voting.uuid}`,
      });
    },

    voteHandler() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'vote',
        size: 'xxl',
        handler: {
          payload: this.getCategorizationSecretary.voting.uuid,
          role: 'Secretary',
        },
      });
    },

    populateFormWithData() {
      this.structure.map((el) =>
        el.subgroups.map((s) =>
          s.criterias.map(async (s) => {
            if (s.uuid in this.prefilledCriteris) {
              if (Array.isArray(this.prefilledCriteris[s.uuid].value)) {
                let valueToPopulate = [];

                for (let file in this.prefilledCriteris[s.uuid].value) {
                  let convertedFile = await this.createFile(
                    this.prefilledCriteris[s.uuid].value[file].original_url,
                    this.prefilledCriteris[s.uuid].value[file].mime
                  );
                  valueToPopulate.push(convertedFile);
                }
                s.value = valueToPopulate;
              } else {
                s.value = this.prefilledCriteris[s.uuid].value;
              }
            }
          })
        )
      );

      // votingDate
    },

    async getScheduleData() {
      this.votingDate = !!this.getCategorizationSecretary.voting_at
        ? this.getCategorizationSecretary.voting_at
        : this.votingDate;

      this.uploadedSchedule = !!this.getCategorizationSecretary
        ?.schedule_attachment.length
        ? [
            await this.createFile(
              this.getCategorizationSecretary.schedule_attachment[0]
                .original_url,
              this.getCategorizationSecretary.schedule_attachment[0].mime
            ),
          ]
        : this.uploadedSchedule;
    },

    async createFile(url, type) {
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: type,
      };
      return new File([data], url.split('/').at(-1), metadata);
    },

    groupGrid(group) {
      if (group.criterias[0].type == 'images') {
        return 'image__grid';
      }
      return 'profile__grid';
    },

    disabledDates(date) {
      return new Date(date).getTime() < Date.now();
    },

    downloadCertificate() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationSecretary.certificate.attachment.original_url
      );
    },

    downloadPDF() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationSecretary.archive.original_url
      );
    },
  },

  validations() {
    return {
      uploadedSchedule: {
        required,
      },
      votingDate: {
        required,
      },
    };
  },
};
</script>

<style lang="sass" scoped>
.voting-date__block
  display: flex
  align-items: center
  flex-direction: column
  margin-top: 4rem
.schedule__upload
  max-width: 40rem
  margin: 7rem 0
.mx-datepicker
  max-width: 40rem
.control__btn-wrapper
  margin-top: 8rem
</style>
