<template>
  <div class="profile__wrapper">
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="`${item.column_type}`"
        >
          <text-field
            :id="item.name"
            v-model="userData[item.name]"
            placeholder="-"
            :title="$t(`profile.certification_agency_${item.name}`)"
            :value-mask="item.mask"
            :type="item.type"
            :is-disabled="!isEditable"
            :is-required="isCreateUser"
            :is-error="$v.userData[item.name].$error"
          />
        </div>
      </div>
    </div>

    <div class="" v-if="isCreateUser">
      <CreatePasswordForm
        :initValidation="initValidation"
        @resetValid="initValidation = false"
        @credentials="(data) => (credentials = data)"
      />
    </div>

    <div class="no-print">
      <div class="control__btn-wrapper">
        <Button
          transparent
          @click.native="
            $router.push({ name: 'access-administrator_certification-agency-list' })
          "
        >
          {{ $t('buttons.back_to_list') }}
        </Button>
        <Button
          icon-name="arrow"
          isRightIcon
          v-if="!isCreateUser && isEditable"
          @click.native="saveUserHandler"
        >
          {{ $t('buttons.save') }}
        </Button>
        <Button
          icon-name="arrow"
          isRightIcon
          v-if="isCreateUser"
          @click.native="saveUserHandler"
        >
          {{ $t('buttons.user_create') }}
        </Button>
        <Button
          icon-name="print"
          isRightIcon
          @click.native="print"
          v-if="!isCreateUser"
        >
          {{ $t('buttons.print') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import validate from '@/mixins/createUserCertificationAgencyByAccessAdminvalidationMixin';
import profile from '@/models/access_administrator/certification_agency_profile';
import CreatePasswordForm from '@/elements/CreatePasswordForm.vue';

export default {
  components: { CreatePasswordForm },
  mixins: [validate],

  data() {
    return {
      userData: {
        full_name: '',
        phone: '',
        edrpou: '',
        working_email: '',
      },
      initValidation: false,
      isValidForm: false,
      credentials: null,
    };
  },

  async created() {
    if (!!!this.$route.params?.activity) {
      this.$router.push({ name: 'access-administrator_content-admin-list' });
    } else {
      if (!this.isCreateUser) {
        await this.$store.dispatch('getUserAccessAdmin', {
          user_uuid: this.$route.params.id,
        });
        await this.setUserData();
      }
    }
  },

  computed: {
    ...mapGetters(['getUserDetailsAccessAdmin']),

    isCreateUser() {
      return this.$route.params.activity === 'create';
    },

    isEditable() {
      return this.$route.params.activity !== 'readonly';
    },

    getModel() {
      return profile;
    },

    getDataForEdit() {
      let obj = {};
      for (let key in this.userData) {
        if (!!this.userData[key]) {
          obj[key] = this.userData[key];
        }
      }
      return obj;
    },
  },

  methods: {
    setUserData() {
      let data = this.getUserDetailsAccessAdmin?.role;
      if (!!data) {
        for (let key in this.userData) {
          let val = !!data[key] ? data[key] : '-';
          this.userData[key] = val;
        }
      }
    },

    saveUserHandler() {
      if (this.isCreateUser) {
        this.$v.$touch();
        this.initValidation = true;

        this.$nextTick(() => {
          if (!this.$v.$invalid && !!this.credentials) {
            const { email, password } = this.credentials;
            let payload = {
              ...this.userData,
              email: email,
              password: password,
            };
            this.$store.dispatch('createCertificationAgencyAccessAdmin', payload);
          }
        });
      } else {
        let payload = {
          ...this.getDataForEdit,
          uuid: this.getUserDetailsAccessAdmin.uuid,
        };
        this.$store.dispatch('updateCertificationAgencyAccessAdmin', {
          data: payload,
        });
      }
    },

    print() {
      window.print();
    },
  },
};
</script>
