<template>
  <div class="profile__wrapper">
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="`${item.column_type}`"
        >
          <div
            :class="{ 'required_date-picker': isCreateUser }"
            v-if="item.type === 'date'"
          >
            <DatePicker
              v-if="item.name === 'term_of_office_start'"
              :format="'DD-MM-YYYY'"
              value-type="YYYY-MM-DD HH:mm:ss"
              :lang="getDateConfig"
              v-model="userData[item.name]"
              :placeholder="$t(`profile.${item.name}`)"
              :disabled="!isEditable"
              :class="{
                'is-error': $v.userData[item.name].$error,
              }"
            />
            <DatePicker
              v-else-if="item.name === 'term_of_office_end'"
              :format="'DD-MM-YYYY'"
              value-type="YYYY-MM-DD HH:mm:ss"
              :lang="getDateConfig"
              v-model="userData[item.name]"
              :placeholder="$t(`profile.${item.name}`)"
              :disabled="!isEditable || !userData.term_of_office_start"
              :disabled-date="disabledDatesEnd"
              :class="{
                'is-error': $v.userData[item.name].$error,
              }"
            />
            <DatePicker
              v-else
              :format="'DD-MM-YYYY'"
              value-type="YYYY-MM-DD HH:mm:ss"
              :lang="getDateConfig"
              v-model="userData[item.name]"
              :placeholder="$t(`profile.${item.name}`)"
              :disabled="!isEditable"
              :class="{
                'is-error': $v.userData[item.name].$error,
              }"
            />
          </div>

          <text-field
            v-else-if="item.name === 'identification_number'"
            :id="item.name"
            v-model="userData[item.name]"
            placeholder="-"
            :title="$t(`profile.${item.name}`)"
            :value-mask="item.mask"
            :is-required="isCreateUser"
            :type="item.type"
            :is-disabled="!isEditable || isPresiding"
            :errorText="getErrorText"
            :is-error="
              !!$v.userData[item.name] && $v.userData[item.name].$error
            "
          />
          <select-field
            v-else-if="item.type === 'select'"
            :id="item.name"
            v-model="userData[item.name]"
            :items="item.options"
            :title="$t(`profile.${item.name}`)"
            :is-required="isCreateUser"
            :errorText="getErrorText"
            :is-error="!!$v.userData[item.name] && $v.userData[item.name].$error"
            :placeholder="$t(`profile.select.${item.name}`)"
            :is-disabled="isPresidingUpdate"
            v-bind="item"
          />

          <text-field
            v-else
            :id="item.name"
            v-model="userData[item.name]"
            placeholder="-"
            :title="$t(`profile.${item.name}`)"
            :value-mask="item.mask"
            :is-required="isCreateUser"
            :type="item.type"
            :is-disabled="!isEditable || isPresidingUpdate"
            :is-error="
              !!$v.userData[item.name] && $v.userData[item.name].$error
            "
          />
        </div>
      </div>
    </div>

    <!-- <div class="" v-if="isCreateUser">
      <CreatePasswordForm
        :initValidation="initValidation"
        @resetValid="initValidation = false"
        @credentials="(data) => (credentials = data)"
      />
    </div> -->

    <div class="no-print">
      <div class="control__btn-wrapper">
        <Button
          transparent
          @click.native="
            $router.push({ name: 'access-administrator_commissioner-list' })
          "
        >
          {{ $t('buttons.back_to_list') }}
        </Button>
        <Button
          icon-name="arrow"
          isRightIcon
          v-if="!isCreateUser && isEditable && !isPresiding"
          @click.native="saveUserHandler"
        >
          {{ $t('buttons.save') }}
        </Button>
        <Button
          icon-name="arrow"
          isRightIcon
          v-if="isCreateUser"
          @click.native="saveUserHandler"
        >
          {{ $t('buttons.user_create') }}
        </Button>
        <Button
          icon-name="print"
          isRightIcon
          @click.native="print"
          v-if="!isCreateUser"
        >
          {{ $t('buttons.print') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import validate from '@/mixins/createUserByAccessAdminvalidationMixin';
import commissioner_profile from '@/models/access_administrator/commissioner_profile';
import presiding_commissioner_profile from '@/models/access_administrator/presiding_commissioner_profile';
import deputy_presiding_commissioner_profile from '@/models/access_administrator/deputy_presiding_commissioner_profile';
import secretary_of_the_commission_profile from '@/models/access_administrator/secretary_of_the_commission_profile';
// import profile from '@/models/access_administrator/profile';
import datePickerConfig from '@/library/date-picker-config';
import DatePicker from 'vue2-datepicker';
import CreatePasswordForm from '@/elements/CreatePasswordForm.vue';
import SelectField from "../../elements/SelectField.vue";

export default {
  components: {
    SelectField,
    DatePicker,
    CreatePasswordForm,
  },
  mixins: [validate],
  data() {
    return {
      userData: {},
      initValidation: false,
      isValidForm: false,
      credentials: null,
      createHandlerName: null,
      updateHandlerName: null,
      selectedRole: 'commissioner',
    };
  },

  async created() {
    if (!!!this.$route.params?.activity) {
      this.$router.push({ name: 'access-administrator_commissioner-list' });
    } else {
      if (!this.isCreateUser) {
        await this.$store.dispatch('getUserAccessAdmin', {
          user_uuid:this.$route.params.id,
          role: this.getSelectedCommissionMember.name.split(/\.?(?=[A-Z])/).join('_').toLowerCase(),
        });
        await this.setUserData();
      }
    }
  },

  mounted() {
    this.setCurrentnData();
  },

  computed: {
    ...mapGetters(['getSelectedCommissionMember', 'getUserDetailsAccessAdmin']),

    getModel() {
      const models = {
        commissioner: commissioner_profile,
        secretary_of_the_commission: secretary_of_the_commission_profile,
        presiding_commissioner: presiding_commissioner_profile,
        deputy_presiding_commissioner: deputy_presiding_commissioner_profile,
      };

      return models[this.selectedRole];
    },

    getDateConfig() {
      return datePickerConfig;
    },

    isCreateUser() {
      return this.$route.params.activity === 'create';
    },

    isEditable() {
      return this.$route.params.activity !== 'readonly';
    },

    isPresiding() {
      return this.selectedRole === 'presiding_commissioner';
    },

    isPresidingUpdate() {
      return this.selectedRole === 'presiding_commissioner' && !this.isCreateUser;
    },

    isValidLength() {
      return this.$v.userData?.identification_number?.minLength;
    },

    getErrorText() {
      return !this.isValidLength ? this.$t('error.field_is_short') : '';
    },

    getDataForEdit() {
      let obj = {};
      for (let key in this.userData) {
        if (!!this.userData[key]) {
          obj[key] = this.userData[key];
        }
      }
      return obj;
    },
  },

  watch: {
    'userData.position': {
      immediate: true,
      handler(value) {
        if(value && value.value) {
          const words = value.value.split('_')
          const arr = words.map(el => {
            return el.charAt(0).toUpperCase() + el.slice(1)
          })
          
          this.createHandlerName = `create${arr.join('')}AccessAdmin`
          this.updateHandlerName = `update${arr.join('')}AccessAdmin`
          this.selectedRole = value.value

          this.$store.commit('SET_SELECTED_COMMISSION_MEMBER', {name: arr.join(''), title: value.name})
        }
      }
    }
  },

  methods: {
    setCurrentnData() {
      this.getModel?.group.map((g) =>
        g.fields.map((f) => {
          this.$set(this.userData, f.name, '');
        })
      );
    },

    setUserData() {
      let data = this.getUserDetailsAccessAdmin?.role;
      if (!!data) {
        for (let key in this.userData) {
          let val = !!data[key] ? data[key] : '-';
          this.userData[key] = val;
        }
        this.userData.term_of_office_start = `${this.userData.term_of_office_start} 00:00:00`;
        this.userData.term_of_office_end = `${this.userData.term_of_office_end} 00:00:00`;
        this.userData.date_of_birth = `${this.userData.date_of_birth} 00:00:00`;
        this.userData.position = {name: data.position, value: data.type}
      }
    },

    disabledDatesEnd(date) {
      return (
        new Date(date).getTime() <=
        new Date(this.userData?.term_of_office_start).getTime()
      );
    },

    saveUserHandler() {
      if (this.isCreateUser) {
        this.$v.$touch();
        this.initValidation = true;

        this.$nextTick(() => {
          if (!this.$v.$invalid /*&& !!this.credentials*/) {
            // const { email, password } = this.credentials;
            let payload = {
              ...this.userData,
              email: this.userData.working_email,
              // password: password,
              position: this.userData.position.name,
              role: this.userData.position.value,
            };
            this.$store.dispatch(this.createHandlerName, payload);
          }
        });
      } else {
        let payload = {
          ...this.getDataForEdit,
          uuid: this.getUserDetailsAccessAdmin.uuid,
          position: this.userData.position.name,
          role: this.userData.position.value,
        };
        this.$store.dispatch(this.updateHandlerName, { data: payload });
      }
    },

    print() {
      window.print();
    },
  },
};
</script>
