<template>
  <div class="main__content">
    <div class="table__header_bar">
      <InputSearch isFilter @detectSearchValue="searchTableHandler" />
      <Button
        isControl
        iconName="star"
        @click.native="createReport"
      >
        {{ $t('sections.certification_agency_change_star_category') }}
      </Button>
    </div>
    <Table
      v-if="!!getTableOfCategorizationsDart"
      :content="getTableData"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import { mapGetters } from 'vuex';

export default {
  components: {
    Table,
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('getTableOfCategorizationsDart');
  },
  computed: {
    ...mapGetters(['getTableOfCategorizationsDart']),

    getTableData() {
      return this.getTableOfCategorizationsDart?.table?.items;
    },
  },

  methods: {
    createReport() {
      this.$router.push({
        name: 'dart_hotel_categorization-create',
        params: {
          activity: 'create',
        },
      });
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfCategorizationsDart');
    },
    tableHandler() {
      this.$store.dispatch('getTableOfCategorizationsDart');
    },
  },
};
</script>
