export const menu = [
  {
    name: 'categorization_requests',
    path_name: 'certificate_agency_hotel_categorizations-list',
    rooth_name: 'categorization'
  },
  {
    name: 'profile',
    path_name: 'certification_agency_profile',
    rooth_name: 'profile'
  },
  {
    name: 'notifications',
    path_name: 'certification_agency_notifications',
    rooth_name: 'notifications'
  },
];
