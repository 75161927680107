<template>
  <div class="profile__wrapper">
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="[
            { profile__file: item.type === 'file' },
            `${item.column_type}`,
          ]"
        >
          <template v-if="item.type === 'file'">
            <FileUpload
              :id="item.name"
              v-model="files[item.name]"
              :is-multiple="item.name === 'statistical_calls'"
              :valid-formats="[
                'doc',
                'docx',
                'pdf',
                'img',
                'jpeg',
                'jpg',
                'png',
              ]"
              :max-size-k-b="25000"
              @uploadFiles="uploadFiles($event, item)"
            />
          </template>
          <text-field
            v-else
            :id="item.name"
            v-model="getCurUserInfo[item.name]"
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :is-icon="item.type === 'text_icon'"
            is-disabled
            icon-name="actual"
          />
        </div>
      </div>
    </div>
    <h4 class="title-h4 profile__title">
      {{ $t('profile.user_qr_code') }}
    </h4>
    <div class="profile__qr-code" v-html="getQrCode" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import profile from '@/models/tour_operator/profile';
import FileUpload from '@/elements/FileUpload.vue';
export default {
  components: { FileUpload },
  data() {
    return {
      files: {
        contract_from_insurance: null,
        statistical_calls: null,
      },
    };
  },

  created() {
    this.$store.dispatch('getUserAccessAdmin', {
      user_uuid: this.$route.params.id,
    });
  },

  computed: {
    ...mapGetters(['getUserDetailsAccessAdmin']),

    getModel() {
      return profile;
    },

    getQrCode() {
      return this.getUserDetailsAccessAdmin?.role.qr_code;
    },

    getCurUserInfo() {
      let obj = {};
      let data = this.getUserDetailsAccessAdmin?.role;
      if (!!data) {
        for (let key in data) {
          let val = !!data[key] ? data[key] : '-';

          obj[key] = `${val}`;
        }

        obj.district = !!data?.district ? data.district.public_name : '-';

        obj.settlement = !!data?.settlement ? data.settlement.public_name : '-';

        obj.state = !!data?.state ? data.state.public_name : '-';
      }
      return obj;
    },
  },
};
</script>
<style lang="sass" scoped>
.profile__file
  transform: translateY(-15px)
</style>
