export const menu = [
  // {
  //   name: 'categorization_requests',
  //   path_name: 'hotel_categorizations-list',
  //   rooth_name: 'categorization'
  // },
  {
    name: 'profile',
    path_name: 'hotel_profile',
    rooth_name: 'profile'
  },
  {
    name: 'notifications',
    path_name: 'hotel_tour-operator_notifications',
    rooth_name: 'notifications'
  },
];
