<template>
  <div
    class="notification-container"
    :class="{
      'notification-container__active': getNotification,
      'error-message': getIsError,
    }"
    v-if="getNotification"
  >
    <svg-icon
      name="close"
      class="notification-container__ico"
      @click.native="closeNotification"
    />
    <div class="status-container">
      <svg-icon :name="iconName" :class="iconName" />
      <p class="notification-title">
        {{
          getIsError
            ? $t('notification.status_error')
            : $t('notification.status_success')
        }}
      </p>
    </div>
    <p class="notification-message">{{ getResponseMessage }}</p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      timerId: null,
    };
  },
  watch: {
    getNotification(val) {
      if (val) {
        this.timerId = setTimeout(() => {
          this.$store.commit('SHOW_NOTIFICATION', false);
        }, 4000);
      }
    },
  },
  computed: {
    ...mapGetters(['getNotification', 'getResponseMessage', 'getIsError']),
    iconName() {
      return this.getIsError ? 'error-mess' : 'ok-status';
    },
  },
  methods: {
    closeNotification() {
      this.$store.commit('SHOW_NOTIFICATION', false);
      clearTimeout(this.timerId);
    },
  },
  destroyed() {
    this.closeNotification();
  },
};
</script>

<style lang="sass">
.ok-status
  width: 20px
  height: 20px
  fill: $success

.notification-container
  position: fixed
  width: 100%
  max-width: 532px
  min-height: 118px
  height: auto
  flex-wrap: wrap
  align-items: center
  padding: 25px !important
  background-color: rgb(249, 252, 247)
  z-index: 999999
  border: 1px solid $success
  border-radius: 12px
  right: 150px
  top: -20px
  opacity: 0
  display: flex

  @include xs
    width: 95vw
    right: 0
    left: 50%
    transform: translateX(-50%)
    margin: 0

.notification-container__active
  top: 20px
  opacity: 1

  animation-name: fadeIn
  -webkit-animation-name: fadeIn
  animation-duration: 0.9s
  -webkit-animation-duration: 0.9s
  animation-timing-function: ease-in-out
  -webkit-animation-timing-function: ease-in-out

.error-message.notification-container
  // background-color: rgba(237, 106, 94, 0.05)
  z-index: 999999
  border: 1px solid $error

.error-mess
  width: 20px
  height: 20px
  fill: $error

.notification-container__ico
  width: 20px
  height: 20px
  position: absolute
  right: 10px
  top: 10px
  cursor: pointer

.status-container
  display: flex
  align-items: center
  width: 100%
  svg
    margin-right: 10px

.notification-title
  font-size: 16px
  color: $gray_notification

.notification-message
  font-size: 20px
  font-weight: 400
  margin-top: 15px
  @media screen and (max-width: 560px)
    font-size: 14px

@keyframes fadeIn
  0%
    opacity: 0.0
    top: -20px
  50%
    opacity: 0.7
    top: 20px
  100%
    opacity: 1
    top: 20px

@-webkit-keyframes fadeIn
  0%
  opacity: 0.0
  top: -20px
  50%
  opacity: 0.7
  top: 20px
  100%
  opacity: 1
  top: 20px

@keyframes fadeOut
  0%
    opacity: 1
    top: 20px
  100%
    opacity: 0
    top: -20px

@-webkit-keyframes fadeOut
  0%
  opacity: 1
  top: 20px
  100%
  opacity: 0
  top: -20px
</style>
